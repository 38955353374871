import React from 'react'

import { classnames } from 'begonya/functions'
import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'

import SectionHead from '../section-head/SectionHead'

import { SectionProps } from './types'
import { path } from '@/core/utils'

const Section: React.FunctionComponent<SectionProps> = ({
  title,
  desc,
  id,
  background,
  children,
  fullHeight,
}) => {
  const app = useApp()

  return (
    <section
      id={id}
      className={classnames('section', fullHeight && 'section--full')}
      style={{
        backgroundImage: `url(${path.asset(app.environment, background?.src)})`,
      }}
    >
      {children}
    </section>
  )
}

export default Section
